import * as Yup from 'yup';
import YupPassword from 'yup-password';
import withFormSubmission from 'components/common/withFormSubmission';
import { TFunction } from 'next-i18next';

YupPassword(Yup);

export interface IValues {
  password_one: string;
  password_two: string;
}

export interface IProps {
  t: TFunction;
  onSubmit: (values: IValues) => void;
}

const withResetPasswordForm = withFormSubmission<IProps, IValues>({
  validationSchema: (props: IProps) =>
    Yup.object({
      password_one: Yup.string()
        .required()
        .password()
        .min(8, props.t('common:passwordErrors.must_contain_8_or_more_characters'))
        .minLowercase(1, props.t('common:passwordErrors.must_contain_at_least_1_lower_case_letter'))
        .minUppercase(1, props.t('common:passwordErrors.must_contain_at_least_1_upper_case_letter'))
        .minNumbers(1, props.t('common:passwordErrors.must_contain_at_least_1_number'))
        .minSymbols(1, props.t('common:passwordErrors.must_contain_at_least_1_special_character')),
      password_two: Yup.string()
        .required()
        .when('password_one', (passwordOne: string, schema: Yup.AnySchema) =>
          schema.test({
            name: 'password_one_password_two',
            message: props.t('common:passwordErrors.confirm_password_does_not_match_new_password'),
            test: (passwordTwo: string) => passwordTwo === passwordOne,
          }),
        )
        .password()
        .min(8, props.t('common:passwordErrors.must_contain_8_or_more_characters'))
        .minLowercase(1, props.t('common:passwordErrors.must_contain_at_least_1_lower_case_letter'))
        .minUppercase(1, props.t('common:passwordErrors.must_contain_at_least_1_upper_case_letter'))
        .minNumbers(1, props.t('common:passwordErrors.must_contain_at_least_1_number'))
        .minSymbols(1, props.t('common:passwordErrors.must_contain_at_least_1_special_character')),
    }),
  mapPropsToValues: () => ({
    password_one: '',
    password_two: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
});

export default withResetPasswordForm;
