import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Trans, useTranslation } from 'i18n';
import CustomDialogTitle from 'components/common/CustomDialogTitle';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { submitForm } from 'utils/form';
import { AuthSelectors } from 'store/selectors';
import { AuthActions } from 'store/actions';
import ResetPasswordForm from './ResetPasswordForm';

export interface IProps extends DialogProps {
  uid: string;
  token: string;
}

const useStyles = makeStyles()((theme) => ({
  mainGridContainer: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  dialogActions: {
    padding: '24px 40px',
  },
  pb32: {
    paddingBottom: 32,
  },
  dialogPaper: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '398px',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
  },
}));

const ResetPasswordModal: React.FC<IProps> = forwardRef((props, ref) => {
  const { uid, token, onClose, ...other } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { isPending, isFulfilled } = useSelector(AuthSelectors.resetPasswordState);

  useEffect(() => {
    dispatch(AuthActions.resetPassword.reset());
  }, [dispatch]);

  useEffect(() => {
    if (isFulfilled) onClose(null, null);
  }, [isFulfilled, onClose]);

  const handleClose = (e: React.MouseEvent) => {
    onClose(e, null);
  };

  const handleOnSubmit = useCallback(
    (data: any) => {
      dispatch(
        AuthActions.resetPassword.request({
          params: {
            uidb64: uid,
            token,
            password_one: data.password_one,
            password_two: data.password_two,
          },
        }),
      );
    },
    [dispatch, uid, token],
  );

  const handleSubmitForm: React.MouseEventHandler = () => {
    submitForm(formRef, false);
  };

  return (
    <Dialog ref={ref} className={classes.dialogPaper} fullScreen={isMobile} {...other}>
      <CustomDialogTitle onClose={handleClose} />
      <DialogContent>
        <Grid className={classes.mainGridContainer} container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5">{t('modal.resetPassword.title')}</Typography>
          </Grid>
          <Grid className={classes.pb32} item xs={12}>
            <Typography style={{ fontWeight: 500 }} color="textSecondary" variant="body2">
              <Trans i18nKey="modal.resetPassword.description" ns="public">
                <div>Strong passwords include:</div>
                <div>∙ 8+ characters</div>
                <div>∙ at least 1 upper case and lowercase</div>
                <div>∙ at least 1 number</div>
                <div>∙ at least 1 special character</div>
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResetPasswordForm innerRef={formRef} onSubmit={handleOnSubmit} t={t} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" style={{ width: 112, borderRadius: 24 }} color="secondary" onClick={handleClose}>
          {t('common:button.cancel')}
        </Button>
        <Button
          variant="contained"
          style={{ width: 112, borderRadius: 24 }}
          color="secondary"
          onClick={handleSubmitForm}
          disabled={isPending}
        >
          {t('common:button.reset')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ResetPasswordModal;
